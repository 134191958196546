.messaging {
    font-size: 18px;
    color: #333;

    li {
        font-size: 18px;
        color: #545454;
        cursor: pointer;
        list-style: square;
        margin-left: 20px;
    }

    h3 {
        margin: 10px 0;
    }

    input {
        outline: none;
        width: 200px;
        height: 30px;
        padding: 10px 20px;
        margin-right: 10px;
        font-size: 18px;
    }

    button {
        outline: none;
        padding: 10px 20px;
        border: none;
        background-color: lightblue;
        font-size: 18px;
        cursor: pointer;
    }
}