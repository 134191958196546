.product-category {
    display: flex;
    flex-direction: column;
    width: 100%;

    h3 {
        color: #333;
        margin-top: 20px;
    }

    .category-list {
        display: flex;
        flex-direction: column;
    }

    .category-list h4 {
        font-size: 24px;
        margin-bottom: 0;
        margin-left: 5px;
        font-weight: 400;
        cursor: pointer;
    }

    .category-list ul {
        margin-left: 25px;
    }

    .category-list ul li {
        font-size: 18px;
        margin: 0;
        color: #797979;
    }

    .category-list .category {
        margin: 10px 0;
    }

    .category .heading {
        font-size: 18px;
        display: flex;
        align-items: center;
        color: #333;
    }
}