.add-product {
  margin-bottom: 5rem;
  .card {
    width: 100%;
    max-width: 500px;
    padding: 1rem;
  }
  form {
    label {
      display: block;
      font-size: 1.4rem;
      font-weight: 500;
      color: var(--color-dark);
    }
    input[type="text"],
    input[type="number"],
    input[type="file"],
    input[type="email"],
    select,
    input[type="password"] {
      display: block;
      font-size: 1.6rem;
      font-weight: 300;
      padding: 1rem;
      margin: 1rem auto;
      width: 100%;
      border: 1px solid #777;
      border-radius: 3px;
      outline: none;
    }
  }
}

.group {
  border: 1px solid var(--dark-blue);
  padding: 5px;
}

.image-preview {
  width: 100%;
  height: 100%;
  max-height: 280px;
  background: var(--dark-blue);
  padding: 1rem;
  border-radius: 3px;
  overflow: hidden;
  // display: none;
  transition: all 0.3s;
}

.image-preview img {
  width: 100%;
}

.image-show {
  display: block;
}
