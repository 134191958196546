.info-box {
  width: max-content;
  height: 7rem;
  padding: 10px 30px;
  margin-right: 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  vertical-align: middle;
  color: #fff;
  transform: translateY(0);
  transition: all 0.3s;

  // background-color: green;

  &:hover {
    cursor: pointer;
    // border: 3px solid #fff;
    transform: translateY(-5px);
  }

  .info-icon {
    padding-right: 10px;
    color: #fff;
  }

  .info-text>* {
    width: max-content;
    color: #fff;
  }

  // .card {
  //   border: 1px solid #ccc;
  //   border-bottom: 3px solid var(--light-blue);
  //   padding: 5px;
  //   background-color: #f5f6fa;
  // }
}

@media screen and (max-width: 600px) {
  .info-box {
    max-width: 100%;
  }
}