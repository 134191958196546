.home {
  min-height: 100vh;
  overflow-x: none;
  width: 100vw;

  & nav {
    padding-bottom: 1.5rem;
    color: #333;

    &>.logo {
      color: #333;
    }
  }

  .container {
    margin: 0;
    padding: 0;
  }

  #background-video {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
  }
}

.hero {
  width: 100vw;
  padding: 0 50px;
}

.home .logo img {
  height: 100px;
  width: 80px;
}

.nav-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  background: #ffffff79;
  margin: 0;
  padding: 0 50px;
}

.initiative h2 {
  font-size: 4vh;
  letter-spacing: -1px;
}

.invent-text {
  font-weight: 800;
}

.t-b {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25vw;
}

.initiative h4 {
  font-weight: 200;
  letter-spacing: 2px;
}

.nav-bar button {
  padding: 10px 20px;
  border-radius: 30px;
}

.logo-texts {
  letter-spacing: 1px;
  line-height: 2%;
}

.logo-texts h4 {
  font-weight: 200;
  letter-spacing: 1px;
}

.home-links {
  display: flex;
  justify-content: center;
  align-items: center;

  & li a {
    color: #fff;
  }

  &>* {
    margin-left: 1rem;
    color: #333;
  }
}

.container.hero {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  padding-left: 100px;
}

.hero-text {
  width: 500px;
  background-color: #333333a8;
  padding: 20px;

  &>* {
    color: #fff;
    margin-bottom: 2rem;
  }

  &>h2 span {
    display: block;
  }

  & .hero-buttons a {
    color: #333;
  }
}

.hero-text p {
  font-size: 3vh;
}

.hero-image {
  margin-top: 3.2%;
  width: 50px;
}

img {
  width: 400px;
  align-self: baseline;
}

@media screen and (max-width: 600px) {
  .hero {
    flex-direction: column;
  }

  .hero-text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin: auto;
    text-align: center;
  }

  .hero-image {
    display: none;
  }
}